.main-header{
  display: flex;
  flex-direction: column;
}

.header{
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 140px;
}

.logo{
  
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: end;
  padding: 0px 50px;
}

ul.menu-items{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-content: center;
  justify-items: center;
  height: 15px;
  list-style: none;
  background-color: rgb(82, 86, 89);
  padding: 15px;
}

 ul.menu-items a{
  text-decoration: none;
  font-weight: bold;
  padding: 15px;
  color: white;
  margin-left: 46px;
  margin-bottom: 5px;

}


ul.menu-items a:hover {
  background-color: white;
  color: rgb(82, 86, 89);
 
} 

ul.menu-items a.active{
  background-color: white;
  color: rgb(82, 86, 89);
}

.local-lang{
  margin-right: 9em;
}

.local-lang span{
  font-size: 10px;
  color: gray;
  margin-right: 20px;
  top: 1.5em;
  position: relative;
  cursor: pointer;
}


.local-lang span:last-child{
  font-size: 10px;
  color: blue;
  margin-right: 20px;
}

.bread_section{
  align-self: center;
  background-color: white;
  width: 90%;
  height: 100%;
  margin-top: 5px;
  overflow: hidden
}


.slide_section{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  
}

.bread_section img.bread_img{
  width: 50%;
}

.bread_section .bread_desc{
  background-color: rgb(153, 159, 172);
  padding: 2.15em;
  width: 50%;
  height: 12.22em;
}

.bread_section .bread_desc h3{
  font-size: 2.23em;
  color: #0000009c;
  
}

.bread_section .bread_desc h3 strong{
  font-weight: 800;
}

.focus_section{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.focus_title{
  width: 50%;
  display: flex;
  flex-direction: column;
  
}

.focus_title h3{
  font-size: 3em;
  padding-left: 2em;
  color: #585252;
  text-align: left;
}

.focus_title p{
  font-size: 1.25em;
  color: #565252;
  padding-left: 5em;
  font-weight: 600;
  line-height: 1.6em;
  text-align: left;
  bottom: 2em;
  position: relative;
  padding-right: 2em;
  
}


.focus_title span{
  font-size: 15px;
  color: #565252;
  padding-left: 6.8em;
  font-weight: 600;
  line-height: 2em;
  text-align: left;
  bottom: 2em;
  position: relative;
  padding-right: 2em;
  margin-top: 3em;
}

.focus_illustration{
  width: 50%;
  padding-top: 20px;

}

.focus_illustration p{
  line-height: 1.5em;
  font-size: 0.9em;
  color: black;
  text-align: left;
}

h3{
  color: #585252;
}

.focus_illustration h3.title{
  font-size: 1.8em;
  padding-left: 2.3em;
}

.headline{
  width: 1px;
  height: 250px;
  background-color: #d6cece;
}

.metier_section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.validate_sec{
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
}

.validate_sec img{
  padding-left: 2.5em;
}

.validate_sec h3{
  padding-right: 20px;
}

.validate_box{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.certification_section{
  width: 98%;
  border: 1px solid #d6cece;
  border-radius: 10px;
  margin-left: 10px;
  display: flex;
  margin-right: 10px;
  flex-direction: column;
  margin-top: 20px;
}

h3.certif_title{
  padding: 15px;
  background-color: gray;
  color: white;
  border-radius: 10px;
  bottom: 1em;
  position: relative;
}


.box_certif{
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  align-items: center;
  align-content: center;
}

.box{
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  padding-right: 1.3em;
}

.box:first-child{
  bottom: 15px;
  position: relative;
}

.box p{
  line-height: 1.5em;
  font-size: 0.9em;
  color: black;
  text-align: center;
  padding: 10px;
}



.box_certif .box img{
  width: 120px;
  height: auto;
  padding: auto;
}

/* REFLEXION PAGE */

.refle_desc{
  display: grid;
  grid-template-columns: (1 1fr);
  /* grid-auto-rows: minmax(50px, auto); */
}

.p_desc{
  grid-row: 1/3;
}

.p_desc p{
  font-size: 18px;
  color: #565252;
  font-weight: 700;
  width: 65%;
  padding: 30px;
  line-height: 1.8em;
}

.sec_desc{
  color: #565252;
  width: 75%;
  padding: 30px;
  position: relative;
  bottom: 4em;
 
}

.sec_desc h3{
  font-size: 28px;
  color: black;
  font-weight: 700;
}

.collapse_desc{
  position: relative;
  bottom: 10px;
}

.collapse_desc span{
  font-size: 20px;
  font-weight: 500;
}

.collapse_desc p{
  font-weight: 400;
  color: #565252;;
  position: relative;
  bottom: 5px;
}


.headline-h{
  margin-left: 2em;
  margin-right: 2em;
  height: 2px;
  background-color: #d6cece;
  position: relative;
  bottom: 4.5em;
}

.sec_desc-sms{
  color: #565252;
  width: 75%;
  padding: 30px;
  position: relative;
  bottom: 7em;
  height: 190px;
}

.sec_desc-sms h3{
  font-size: 28px;
  color: black;
  font-weight: 700;
}

.sec_desc-vis{
  color: #565252;
  width: 75%;
  padding: 30px;
  position: relative;
  bottom: 7em;
  height: 130px;
}

.sec_desc-vis h3{
  font-size: 28px;
  color: black;
  font-weight: 700;
}

.interv_section{
  display: grid;
  grid-template-columns: repeat(2, 1fr)
 
}

.title_int{
  grid-column: 1;
  grid-row: 1/3;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  padding: 2em;
  position: relative;
  bottom: 3em;
  height: 2em;
}

.title_int h3{
  font-size: 2em;
  padding: 30px;
}


.title_int p{
  font-size: 18px;
  color: #565252;
  font-weight: 700;
  width: 85%;
  padding: 30px;
  line-height: 1.8em;
  position: relative;
  bottom: 5em;
}


.client_type{
  position: relative;
  top: 2em;
 
}
.client_type h6{
  font-size: 18px;
  color: #565252;
  font-weight: 700;
}

.exempl_cron{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 50px;
}

.exempl_cron h3{
  font-size: 2em;
}

.exempl_cron .column_box{
  display: flex;
  flex-direction: row;
}

.box_exemple:first-child{
  width: 9em;
  height: 9em;
  padding: 2em;
  background-color: #acca0a;
  border-radius: 10px;
  margin-right: 20px;

}

.box_exemple{
  width: 9em;
  height: 9em;
  padding: 2em;
  background-color: #999FAC;
  border-radius: 10px;
  margin-right: 20px;

}

.box_exemple h6{
  font-size: 15px;
  line-height: 25px;
  position: relative;
  bottom: 20px;
  text-align: center;

}

.protect_server{
  display: grid;
  grid-template-columns: repeat(1 1fr);
  padding: 50px;
  position: relative;
  bottom: 4em;
}

.protect_server h3{
  color: #acca0a;
  font-size: 28px;
  width: 50%;
}

.headline-e{
  height: 2px;
  background-color: #d6cece; 
}

.contex_sect{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-self: flex-start;
  align-items: center;
}

.contex_sect h3{
  font-size: 20px;
  color:  #585252;
}

.contex_sect p.first{
  position: relative;
  right: 16%;
}

.contex_sect p.three{
  position: relative;
  right: 9%;
}

.certif_section{
  padding: 30px;
  font-size: 28px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-self: start;
  justify-items: flex-start;
  justify-self: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}


.box_certification{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  align-content: center;
  align-items: flex-start;
  padding-bottom: 1.5em;
}

.box_certification .headline-e{
  width: 100%;
  height: 1px;
  background-color: #d6cece;
}

.box_certif_content{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  justify-self: center;
  align-self: flex-start;
  align-items: center;
  width: 100%;
  align-content: center;
  padding-top: 5px;
  padding-left: 2em;
}


.box_certif_content p{
  font-size: 15px;
  font-weight: 600;
  color: #454444;
  padding-left: 2em;
}

.headline-bold{
  height: 12px;
  background-color: #d6cece;
  width: 87%;
  margin-left: 6em;
}

.box_certif_content h6{
  width: 100px;
  text-align: center;
}

.box_certif_content_final p{
  font-size: 17px;
  color: #403e3e;
  position: relative;
  bottom: 2em;
}

.partenaire_section{
  display: grid;
  grid-template-columns: repeat(1 1fr);
  padding-left: 2em;
}

.partenaire_section h3{
  font-size: 2.3em;
  padding-top: 40px;
}

.integrate_sec{

}

.integrate_logos{
  padding-bottom: 2em;
}

.contact_section{
  padding: 30px;
}

.contact_section h3{
  font-size: 28px;
  font-weight: 800;
}

.email_section{
  font-size: 18px;
  color: #007fff;
  padding-top: 20px;
  padding-bottom: 1em;
}

.formulaire_contact{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.semi-block{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3em;
 
}

.semi-block input{
  padding: 7px;
  margin-bottom: 20px;
}

.formulaire_contact form input[type="submit" i]{
  padding: 10px;
  border-radius: 20px;
  background-color: #999fac;
  color: white;
  font-weight: bold;
  border: 1px;
  cursor: pointer;
}